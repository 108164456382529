import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutWidth,
} from '../../store/actions';
import { ActiveContext } from '../../Context/ActiveContext';

// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Layout = (props) => {
    const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    const { active, setActive, collapse, setCollapse, collapse1, setCollapse1 } =
        useContext(ActiveContext);

    useEffect(() => {
        if (props.isPreloader === true) {
            document.getElementById('preloader').style.display = 'block';
            document.getElementById('status').style.display = 'block';

            setTimeout(function () {
                document.getElementById('preloader').style.display = 'none';
                document.getElementById('status').style.display = 'none';
            }, 2500);
        } else {
            document.getElementById('preloader').style.display = 'none';
            document.getElementById('status').style.display = 'none';
        }

        // Scroll Top to 0
        window.scrollTo(0, 0);
        // let currentage = capitalizeFirstLetter(props.location.pathname);

        // document.title =
        //   currentage + "BLOCKTRACE";
        if (props.leftSideBarTheme) {
            props.changeSidebarTheme(props.leftSideBarTheme);
        }

        if (props.layoutWidth) {
            props.changeLayoutWidth(props.layoutWidth);
        }

        if (props.leftSideBarType) {
            props.changeSidebarType(props.leftSideBarType);
        }
        if (props.topbarTheme) {
            props.changeTopbarTheme(props.topbarTheme);
        }
    }, []); // eslint-disable-line

    const toggleMenuCallback = () => {
        if (props.leftSideBarType === 'default') {
            props.changeSidebarType('condensed', isMobile);
        } else if (props.leftSideBarType === 'condensed') {
            props.changeSidebarType('default', isMobile);
        }
    };

    return (
        <>
            <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                    </div>
                </div>
            </div>

            <div id="layout-wrapper">
                <Header toggleMenuCallback={toggleMenuCallback} setActive={setActive} />
                <Sidebar
                    type={props.leftSideBarType}
                    isMobile={isMobile}
                    active={active}
                    setActive={setActive}
                    collapse={collapse}
                    setCollapse={setCollapse}
                    collapse1={collapse1}
                    setCollapse1={setCollapse1}
                />
                <div className="main-content">{props.children}</div>
                <Footer />
            </div>
        </>
    );
};

const mapStatetoProps = (state) => {
    return {
        ...state.Layout,
    };
};
export default connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutWidth,
})(Layout);
