import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
    return (
        <>
            <footer className="footer" style={{ zIndex: 1000 }}>
                <Container fluid={true}>
                    <Row>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                Copyright © {new Date().getFullYear()} BLOCKTRACE, LLC.
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Footer;
