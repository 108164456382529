import { encryptPassword } from '../Utils/encrypt';

const Auth = {
    login: async (user) => {
        const encryptedPassword = encryptPassword(user.password)

        const res = await fetch('api/user/login', {
            method: 'post',
            body: JSON.stringify({...user, password: encryptedPassword }),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (res.status === 200) {
            return res.json().then((data) => data);
        } else {
            const errorBody = res.status === 401 ? 'Invalid username or password!' : `An error occurred during login. (${res.statusText})`

            return {
                isAuthenticated: false,
                user: {
                    username: '',
                    role: '',
                    organization: '',
                    _id: '',
                    email: '',
                },
                message: {
                    msgBody: errorBody,
                    msgError: true,
                },
            };
        }
    },

    register: async (user) => {
        const res = await fetch('api/user/register', {
            method: 'post',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await res.json();
        return data;
    },

    logout: async () => {
        const res = await fetch('api/user/logout');
        const data = await res.json();
        return data;
    },

    isAuthenticated : async () => {
        const res = await fetch('api/user/authenticated', {
            method: 'GET',
            headers: {
              'Cache-Control': 'no-cache',
            },
        });

        if (res.status === 200) {
            return res.json().then(data => data);
        } else {
            return {
                isAuthenticated: false,
                user: {
                    username: '',
                    role: '',
                    _id: '',
                },
            };
        }
    },
};

export default Auth;
