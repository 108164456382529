import React, { createContext, useState, useEffect} from 'react';
import AuthService from '../Services/AuthService';
import Spinner from '../components/Spinner/Spinner';
import '../components/Spinner/Spinner.css'

export const AuthContext = createContext();

const Auth = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        AuthService.isAuthenticated().then(data => {
            setUser(data.user);
            setIsAuthenticated(data.isAuthenticated);
            setIsLoaded(true);
        });
    }, []);

    return (
        <div>
            {!isLoaded ?
                <div className="page">
                    <div className="box">
                        <Spinner className="spinner"/>
                    </div>
                </div>
            :
            <AuthContext.Provider value={{
                user,
                setUser,
                isAuthenticated,
                setIsAuthenticated,
            }}>
                { children }
            </AuthContext.Provider>}
        </div>
    )
}

export default Auth;