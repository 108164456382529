import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Select from 'react-select';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Label,
    Input,
    FormGroup,
    CardSubtitle,
} from 'reactstrap';
//import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import logoLarge from '../../assets/images/logos/BlockTrace_Logo.webp';
import logoLightSvg from '../../assets/images/logos/block.svg';
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from '../../store/actions';
import './Header.css';

const tickets = [
    {
        label: 'Tickets',
        options: [
            { label: 'Bug', value: 'bug' },
            { label: 'Feature Request', value: 'feature_request' },
            { label: 'How To', value: 'how_to' },
            { label: 'General', value: 'general' },
        ],
    },
];

const Header = ({ toggleLeftmenu, leftMenu, leftSideBarType, changeSidebarType, setActive }) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [supportModal, setSupportModal] = useState(false);
    const [disDefault, setdisDefault] = useState(0);
    const [threshhold, setthreshhold] = useState(0);
    const [description, setDescription] = useState(null);
    const [type, setType] = useState(null);
    const [message, setMessage] = useState(null);

    const openSupportModal = () => {
        setSupportModal(!supportModal);
    };

    const closeSupportModal = () => {
        setSupportModal(false);
    };

    function threshholdDefault(event) {
        var count = event.target.value.length;
        if (count > 0) {
            setdisDefault(true);
        } else {
            setdisDefault(false);
        }
        setthreshhold(event.target.value.length);
    }

    const onChange = (e) => {
        threshholdDefault(e);
        const ticket_description = e.target.value;
        setDescription(ticket_description);
    };

    const onType = (e) => {
        const ticket_type = e.value;
        setType(ticket_type);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        Axios.post('api/support', {
            type: type,
            description: description,
        }).then((res) =>
            res.status === 200 ? setMessage(res.data) : setMessage('Error: Please Try Again'),
        );
    };

    const clearForm = () => {
        setDescription(null);
        //setType(null)
        setthreshhold(0);
        document.getElementById('form').reset();
    };

    if (message) {
        setTimeout(() => {
            setDescription(null);
        }, 1000);
        setTimeout(() => {
            setMessage(null);
        }, 2000);
        setTimeout(() => {
            closeSupportModal();
        }, 2000);
    }

    function tToggle() {
        toggleLeftmenu(!leftMenu);
        if (leftSideBarType === 'default') {
            changeSidebarType('condensed', isMobile);
        } else if (leftSideBarType === 'condensed') {
            changeSidebarType('default', isMobile);
        }
    }

    const activate = () => {
        setActive({
            home: true,
            admin: false,
            dashboard: false,
            connections: false,
            documentation: false,
        });
    };

    return (
        <>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div
                            className="navbar-brand-box"
                            style={{ transition: 'width .3s ease-in' }}
                        >
                            <Link
                                to="/"
                                onClick={() => {
                                    activate();
                                }}
                                className="logo logo-light"
                            >
                                <span className="logo-sm">
                                    <img
                                        src={logoLightSvg}
                                        alt="Light BlockTrace Logo"
                                        height="22"
                                        width="21"
                                        style={{ transition: 'width .3s ease-in' }}
                                    />
                                </span>
                                <span
                                    className="logo-lg"
                                    style={{ transition: 'width .3s ease-in' }}
                                >
                                    <img
                                        src={logoLarge}
                                        alt="Large BlockTrace Logo"
                                        height="23"
                                        width="163"
                                        style={{ transition: 'width .3s ease-in' }}
                                        loading="lazy"
                                    />
                                </span>
                            </Link>
                        </div>
                        <button
                            type="button"
                            onClick={() => {
                                tToggle();
                            }}
                            className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                            aria-label="Vertical menu button"
                        >
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                    </div>
                    <div className="d-flex">
                        <Button
                            color="deep-purple"
                            type="button"
                            onClick={openSupportModal}
                            className="btn btn-sm px-3 font-size-13 header-item waves-effect ms-2 text-blue"
                            aria-label="Support button"
                        >
                            <i className="bx bx-support me-1"></i>
                            Support
                        </Button>
                        <ProfileMenu />
                    </div>
                </div>
            </header>

            <Modal
                isOpen={supportModal}
                role="dialog"
                autoFocus={true}
                centered={true}
                toggle={() => {
                    setSupportModal(!supportModal);
                }}
            >
                <div className="modal-content">
                    <Form onSubmit={onSubmit} id="form">
                        <ModalHeader
                            toggle={() => {
                                setSupportModal(!supportModal);
                            }}
                        >
                            File A Support Ticket
                            <CardSubtitle className="font-size-12 text-muted mt-1">
                                Once a ticket has been received, our support team will issue a
                                response promptly to the email associated with this account.
                            </CardSubtitle>
                        </ModalHeader>
                        <ModalBody className="ms-5">
                            <div className="form-group row">
                                <div className="col-md-10">
                                    <Label
                                        htmlFor="subscriptions"
                                        className="font-size-12 col-form-label mb-0"
                                    >
                                        Type
                                    </Label>
                                    <FormGroup className="mb-2 mt-0 sm select2-container">
                                        <Select
                                            isMulti={false}
                                            onChange={onType}
                                            options={tickets}
                                            classNamePrefix="select2-selection"
                                            closeMenuOnSelect={true}
                                            required
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-10">
                                    <Label htmlFor="description" className="col-form-label">
                                        Description
                                    </Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        maxLength="500"
                                        rows="4"
                                        value={description ? description : ''}
                                        placeholder="Write a brief description here."
                                        onChange={onChange}
                                        required
                                    />
                                    {disDefault ? (
                                        <span className="badgecount badge badge-success mt-2">
                                            {threshhold} / 500{' '}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {message ? <p>{message}</p> : null}
                            {description && (
                                <Button type="button" color="danger" onClick={clearForm}>
                                    Clear
                                </Button>
                            )}
                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                        </ModalFooter>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

const mapStatetoProps = (state) => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
})(Header);
