const Connection = {
    getAllConnections: async () => {
        try {
            const res = await fetch('api/connections');
            if (res.status !== 401) {
                return res.json().then((data) => data);
            }
        } catch (error) {
            console.error(error);
        }
    },

    getAllQueries: async () => {
        try {
            const res = await fetch('api/queries');
            if (res.status === 200) {
                return res.json().then((data) => data);
            }
        } catch (error) {
            console.error(error);
        }
    },
};

export default Connection;
