import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import AuthProvider from './Context/AuthContext';
import ActiveProvider from './Context/ActiveContext';
import ConfigProvider from './Context/ConfigContext';
import ConnectionProvider from './Context/ConnectionContext';
import GoogleMapsApiProvider from './Context/GoogleMapsApiContext';
import store from './store';
import './index.css';

//Themes
import './themes/lightTheme';

const consoleErr = console.error;
const consoleWarn = console.warn;

const SUPPRESSED_ERRORS = [
    'Support for defaultProps will be removed from function components in a future major release.',
    'findDOMNode is deprecated',
];

const SUPPRESSED_WARNINGS = ['google.maps.Marker is deprecated'];
console.error = function filterWarnings(msg, ...args) {
    if (
        typeof msg !== 'string' ||
        (typeof msg === 'string' && !SUPPRESSED_ERRORS.some((entry) => msg.includes(entry)))
    ) {
        consoleErr(msg, ...args);
    }
};

console.warn = function filterWarnings(msg, ...args) {
    if (
        typeof msg !== 'string' ||
        (typeof msg === 'string' && !SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry)))
    ) {
        consoleWarn(msg, ...args);
    }
};

const app = (
    <Provider store={store}>
        <AuthProvider>
            <ConfigProvider>
                <ActiveProvider>
                    <ConnectionProvider>
                        <GoogleMapsApiProvider>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </GoogleMapsApiProvider>
                    </ConnectionProvider>
                </ActiveProvider>
            </ConfigProvider>
        </AuthProvider>
    </Provider>
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(app);
