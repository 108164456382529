import React, { createContext, useState } from 'react';

export const GoogleMapsApiContext = createContext();

const GoogleMapsApiProvider = ({ children }) => {
    const [googleMapsApiLoaded, setGoogleMapsApiLoaded] = useState(false);
    const [loadError, setLoadError] = useState(null);
    return (
        <GoogleMapsApiContext.Provider
            value={{ googleMapsApiLoaded, setGoogleMapsApiLoaded, loadError, setLoadError }}
        >
            {children}
        </GoogleMapsApiContext.Provider>
    );
};

export default GoogleMapsApiProvider;
