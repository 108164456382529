import React from 'react';

//Simple bar
import SimpleBar from 'simplebar-react';

import SidebarContent from './SidebarContent';

const Sidebar = ({ type, active, setActive, collapse, setCollapse, collapse1, setCollapse1 }) => {
    return (
        <>
            <div
                className="vertical-menu shadow-lg"
                style={{ transition: 'width .3s ease-in', zIndex: 1000 }}
            >
                <div data-simplebar className="h-100">
                    {type !== 'condensed' ? (
                        <SimpleBar style={{ maxHeight: '100%' }}>
                            <SidebarContent
                                active={active}
                                setActive={setActive}
                                collapse={collapse}
                                setCollapse={setCollapse}
                                collapse1={collapse1}
                                setCollapse1={setCollapse1}
                                type={type}
                            />
                        </SimpleBar>
                    ) : (
                        <SidebarContent
                            active={active}
                            setActive={setActive}
                            collapse={collapse}
                            setCollapse={setCollapse}
                            collapse1={collapse1}
                            setCollapse1={setCollapse1}
                            type={type}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Sidebar;
