import React from 'react'

const Message = props => {
    return (
        <div className="message" role="alert">
            {props.message.msgBody}
        </div>
    )
}

export default Message;