import React, { createContext, useContext, useEffect, useState } from 'react';
import ConnectionService from '../Services/ConnectionService';
import { AuthContext } from '../Context/AuthContext';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
        return (images[item.replace('./', '')] = r(item));
    });
    return images;
}

const images = importAll(
    require.context('../assets/images/connections', false, /\.(png|jpe?g|svg|webp)$/),
);

export const ConnectionContext = createContext();

const Connection = ({ children }) => {
    const [connections, setConnections] = useState([]);
    const [activeConnection, setActiveConnection] = useState(
        () => JSON.parse(localStorage.getItem('activeConnection')) || null,
    );
    const [queries, setQueries] = useState(null);
    const [activeQuery, setActiveQuery] = useState(null);
    const [activeQueries, setActiveQueries] = useState(null);
    const [activeQueryTab, setActiveQueryTab] = useState(0);
    const [currentQueries, setCurrentQueries] = useState(null);
    const [currentQuery, setCurrentQuery] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [disDefault, setdisDefault] = useState(0);
    const [threshhold, setthreshhold] = useState(0);

    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (isAuthenticated) {
            ConnectionService.getAllConnections().then(({ connections }) => {
                connections.map(async (conn) => {
                    const image = images.hasOwnProperty(`${conn.type.toLowerCase()}.webp`);
                    if (image) {
                        conn.img = images[`${conn.type.toLowerCase()}.webp`];
                    }

                    return conn;
                });

                setConnections(connections);
            });

            ConnectionService.getAllQueries().then((data) => {
                setQueries(data.queries);
            });
        }
    }, [isAuthenticated]);

    return (
        <ConnectionContext.Provider
            value={{
                connections,
                setConnections,
                activeConnection,
                setActiveConnection,
                queries,
                setQueries,
                activeQuery,
                setActiveQuery,
                activeQueries,
                setActiveQueries,
                currentQueries,
                setCurrentQueries,
                currentQuery,
                setCurrentQuery,
                message,
                setMessage,
                loading,
                setLoading,
                activeQueryTab,
                setActiveQueryTab,
                disDefault,
                setdisDefault,
                threshhold,
                setthreshhold,
            }}
        >
            {children}
        </ConnectionContext.Provider>
    );
};

export default Connection;
