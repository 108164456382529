import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../Context/AuthContext';
import Axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import './Sidebar.css';
import { Collapse } from 'reactstrap';

const SidebarContent = ({
    active,
    setActive,
    collapse,
    setCollapse,
    collapse1,
    setCollapse1,
    type,
}) => {
    const { isAuthenticated, user } = useContext(AuthContext);
    const location = useLocation();

    async function token() {
        const res = await Axios.get('api/user/check');
        if (res.data) {
            var d = new Date();
            let access_token = jwtDecode(res.data);
            if (access_token.exp * 1000 < d.getTime()) {
                await Axios.post('api/user/idle-logout', {
                    _id: user._id,
                });

                window.location.reload();
            } else {
                await Axios.post('api/user/token', {
                    method: 'POST',
                    body: JSON.stringify({ user: user }),
                });
            }
        } else {
            await Axios.post('api/user/idle-logout', {
                _id: user._id,
            });

            window.location.reload();
        }
    }

    const activeHome = () => {
        token();
        setCollapse(false);
        setActive({
            home: true,
            admin: false,
            dashboard: false,
            connections_tab: false,
            connections: false,
            connection_details: false,
            documentation: false,
        });
    };

    // const activeAdmin = () => {
    //     token()
    //     setActive({
    //         home: false,
    //         admin: true,
    //         dashboard: false,
    //         connections_tab: false,
    //         connections: false,
    //         connection_details: false,
    //         documentation: false
    //     })
    // }

    const activeDashboard = () => {
        token();
        setActive({
            home: false,
            admin: true,
            dashboard: true,
            connections_tab: false,
            connections: false,
            connection_details: false,
            documentation: false,
        });
    };

    const activeConnections = () => {
        token();
        setActive({
            home: false,
            admin: true,
            dashboard: false,
            connections_tab: true,
            connections: true,
            connection_details: false,
            documentation: false,
        });
    };

    // const activeConnectionTab = () => {
    //     token()
    //     setActive({
    //         home: false,
    //         admin: true,
    //         dashboard: false,
    //         connections_tab: true,
    //         connections: false,
    //         connection_details: false,
    //         documentation: false
    //     })
    // }

    const activeConnectionDetails = () => {
        token();
        setActive({
            home: false,
            admin: true,
            dashboard: false,
            connections_tab: true,
            connections: false,
            connection_details: true,
            documentation: false,
        });
    };

    const activeDocumentation = () => {
        token();
        setCollapse(false);
        setActive({
            home: false,
            admin: false,
            dashboard: false,
            connections_tab: false,
            connections: false,
            connection_details: false,
            documentation: true,
        });
    };

    useEffect(() => {
        var pathName = location.pathname.substring(1);
        var route = pathName === '' ? 'home' : pathName;
        var keys = Object.keys(active);

        const mapThroughKeys = () => {
            setActiveMenuItem(route, keys);
        };

        function setActiveMenuItem(route, keys) {
            keys.map((i) => {
                if (
                    route !== 'dashboard' &&
                    route !== 'connections_tab' &&
                    route !== 'connections' &&
                    route !== 'connection_details' &&
                    i === route
                ) {
                    setActive({
                        [route]: true,
                    });
                } else if ((route === 'dashboard' || route === 'connections_tab') && i === route) {
                    setActive({
                        [route]: true,
                        admin: true,
                    });
                } else if (route === 'connections') {
                    setActive({
                        connections: true,
                        admin: true,
                        connections_tab: true,
                    });
                } else if (route === 'connection-details') {
                    setActive({
                        admin: true,
                        connections_tab: true,
                        connection_details: true,
                    });
                }

                return active;
            });
        }

        mapThroughKeys();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const authenticatedNavBar = () => {
        return (
            <>
                <div id="sidebar-menu">
                    <ul
                        className="metismenu list-unstyled"
                        id="side-menu"
                        style={{ transition: 'width 3s ease' }}
                    >
                        <li className="menu-title">{'Menu'} </li>
                        {user.role === 'admin' || 'user' ? (
                            <li id="link">
                                <Link
                                    to="/"
                                    className={active.home ? 'mm-active waves-affect' : ''}
                                    onClick={activeHome}
                                    aria-label="BlockTrace home page"
                                >
                                    <i
                                        className="fas fa-home ms-2"
                                        style={{ fontSize: '16px' }}
                                    ></i>
                                    <span>{'Home'}</span>
                                </Link>
                            </li>
                        ) : null}
                        {user.role === 'admin' ? (
                            <>
                                <li id="link">
                                    <Link
                                        id="accordian"
                                        to="#"
                                        className={`${
                                            active.admin ? 'mm-active waves-affect' : ''
                                        }`}
                                        onClick={() => {
                                            setCollapse(!collapse);
                                        }}
                                    >
                                        {type !== 'condensed' && (
                                            <i
                                                className="bx bx-chevron-up adminChevronTransform"
                                                style={{
                                                    width: '17px',
                                                    height: '17px',
                                                    fontSize: '17px',
                                                    transition: 'transform 200ms linear',
                                                    transform: `rotateZ(${
                                                        collapse ? 0 : '180deg'
                                                    })`,
                                                    display: 'inline-block',
                                                    float: 'right',
                                                }}
                                            />
                                        )}
                                        <i
                                            className="fas fa-tools ms-2"
                                            style={{ fontSize: '16px' }}
                                        />
                                        <span>{'Admin'}</span>
                                    </Link>
                                    {type === 'condensed' && (
                                        <ul
                                            className="sub-menu adminSubmenu"
                                            style={{
                                                transition: 'width .3s ease-in',
                                                zIndex: 1000,
                                            }}
                                        >
                                            <li>
                                                <Link
                                                    to="/dashboard"
                                                    className={`${
                                                        active.dashboard
                                                            ? 'mm-active waves-affect'
                                                            : ''
                                                    }`}
                                                    onClick={activeDashboard}
                                                >
                                                    {'Dashboard'}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className={`${
                                                        active.connections_tab
                                                            ? 'mm-active waves-affect'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        setCollapse1(!collapse1);
                                                    }}
                                                >
                                                    {'Connections'}
                                                </Link>
                                                <ul
                                                    className="sub-menu connectionsSubmenu"
                                                    style={{
                                                        transition: 'width .3s ease-in',
                                                        zIndex: 1000,
                                                    }}
                                                >
                                                    <li>
                                                        <Link
                                                            to="/connections"
                                                            className={`${
                                                                active.connections
                                                                    ? 'mm-active waves-affect'
                                                                    : ''
                                                            }`}
                                                            onClick={activeConnections}
                                                        >
                                                            {'Connections'}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="/connection-details"
                                                            className={`${
                                                                active.connection_details
                                                                    ? 'mm-active waves-affect'
                                                                    : ''
                                                            }`}
                                                            onClick={activeConnectionDetails}
                                                        >
                                                            {'Connection Details'}
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                                {type !== 'condensed' && (
                                    <Collapse isOpen={collapse}>
                                        <ul
                                            className="sub-menu"
                                            style={{
                                                transition: 'width .3s ease-in',
                                                zIndex: 1000,
                                            }}
                                        >
                                            <li>
                                                <Link
                                                    to="/dashboard"
                                                    className={`${
                                                        active.dashboard
                                                            ? 'mm-active waves-affect'
                                                            : ''
                                                    }`}
                                                    onClick={activeDashboard}
                                                >
                                                    {'Dashboard'}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    id="accordian"
                                                    to="#"
                                                    className={`${
                                                        active.connections_tab
                                                            ? 'mm-active waves-affect'
                                                            : ''
                                                    }`}
                                                    onClick={() => {
                                                        setCollapse1(!collapse1);
                                                    }}
                                                >
                                                    <div
                                                        style={{ display: 'flex', width: '185px' }}
                                                    >
                                                        {'Connections'}
                                                        <i
                                                            className={`${
                                                                active.connections_tab
                                                                    ? 'mm-active waves-affect bx bx-chevron-up adminChevronTransform'
                                                                    : 'bx bx-chevron-up adminChevronTransform'
                                                            }`}
                                                            style={{
                                                                width: '17px',
                                                                height: '17px',
                                                                fontSize: '17px',
                                                                transition:
                                                                    'transform 200ms linear',
                                                                transform: `rotateZ(${
                                                                    collapse1 ? 0 : '180deg'
                                                                })`,
                                                                display: 'inline-block',
                                                                justifyContent: 'flex-end',
                                                                marginLeft: '65px',
                                                            }}
                                                        />
                                                    </div>
                                                </Link>
                                            </li>
                                            <ul
                                                className="connection-sub-menu"
                                                style={{
                                                    transition: 'width .3s ease-in',
                                                    zIndex: 1000,
                                                }}
                                            >
                                                <Collapse isOpen={collapse1}>
                                                    <li>
                                                        <Link
                                                            to="/connections"
                                                            className={`${
                                                                active.connections
                                                                    ? 'mm-active waves-affect'
                                                                    : ''
                                                            }`}
                                                            onClick={activeConnections}
                                                        >
                                                            {'Connections'}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            to="/connection-details"
                                                            className={`${
                                                                active.connection_details
                                                                    ? 'mm-active waves-affect'
                                                                    : ''
                                                            }`}
                                                            onClick={activeConnectionDetails}
                                                        >
                                                            {'Connection Details'}
                                                        </Link>
                                                    </li>
                                                </Collapse>
                                            </ul>
                                        </ul>
                                    </Collapse>
                                )}
                            </>
                        ) : null}
                        {user.role === 'admin' || 'user' ? (
                            <li id="link">
                                <Link
                                    to="/documentation"
                                    className={active.documentation ? 'mm-active waves-effect' : ''}
                                    onClick={activeDocumentation}
                                    aria-label="Documentation"
                                >
                                    <i
                                        className="fas fa-book ms-2"
                                        style={{ fontSize: '16px' }}
                                    ></i>
                                    <span>{'API Docs'}</span>
                                </Link>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </>
        );
    };

    return <div>{!isAuthenticated ? null : authenticatedNavBar()}</div>;
};

export default SidebarContent;
