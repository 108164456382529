import React, { useContext, useEffect, lazy, Suspense } from 'react';
import axios from 'axios';
import Layout from './components/VerticalLayout/';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';

// Suspense Fallback
import Fallback from './components/Spinner/Spinner';

//Context
import { AuthContext } from './Context/AuthContext';
import { ConfigContext } from './Context/ConfigContext';
import { GoogleMapsApiContext } from './Context/GoogleMapsApiContext';

//Route Components
import PrivateRoute from './hocs/PrivateRoute';
import UnPrivateRoute from './hocs/UnPrivateRoute';

//Non Auth Components
import Login from './pages/Login/Login';
const Forgot = lazy(() => import('./pages/Forgot/index'));
const Authenticate = lazy(() => import('./pages/2fa/index'));

//Auth Components
const Home = lazy(() => import('./pages/Dashboard/index'));
const Admin = lazy(() => import('./pages/Admin/Admin'));
const Connections = lazy(() => import('./pages/Connections/Connections'));
const ConnectionDetails = lazy(() =>
    import('./pages/Connections/ConnectionDetails/ConnectionDetails'),
);
const Documentation = lazy(() => import('./pages/Documentation/index'));

const App = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const { config, setConfig } = useContext(ConfigContext);
    const { setGoogleMapsApiLoaded, setLoadError } = useContext(GoogleMapsApiContext);
    const currentRoute = useLocation().pathname;

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await axios.get('internal/configuration');
                setConfig(response.data);
            } catch (error) {
                console.error('App: error fetching config:', error);
              }
        };
        fetchConfig();
    }, [setConfig]);

    const UNPRIVATE_LOGIN = (
        <Route
            path="/login"
            element={
                <UnPrivateRoute>
                    <Login />
                </UnPrivateRoute>
            }
        />
    );

    const UNPRIVATE_FORGOT = (
        <Route
            path="/forgot"
            element={
                <UnPrivateRoute>
                    <Suspense fallback={<Fallback />}>
                        <Forgot />
                    </Suspense>
                </UnPrivateRoute>
            }
        />
    );

    const UNPRIVATE_AUTHENTICATE = (
        <Route
            path="/authenticate"
            element={
                <UnPrivateRoute>
                    <Suspense fallback={<Fallback />}>
                        <Authenticate />
                    </Suspense>
                </UnPrivateRoute>
            }
        />
    );

    const PRIVATE_HOME = (
        <Route
            path="/"
            element={
                <PrivateRoute roles={['admin', 'user']}>
                    <Layout>
                        <Suspense fallback={<Fallback />}>
                            <Home />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    const PRIVATE_DASHBOARD = (
        <Route
            path="/dashboard"
            element={
                <PrivateRoute roles={['admin']}>
                    <Layout>
                        <Suspense fallback={<Fallback />}>
                            <Admin />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    const PRIVATE_CONNECTIONS = (
        <Route
            path="/connections"
            element={
                <PrivateRoute roles={['admin']}>
                    <Layout>
                        <Suspense fallback={<Fallback />}>
                            <Connections />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    const PRIVATE_CONNECTION_DETAILS = (
        <Route
            path="/connection-details"
            element={
                <PrivateRoute roles={['admin']}>
                    <Layout>
                        <Suspense fallback={<Fallback />}>
                            <ConnectionDetails />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    const PRIVATE_DOCUMENTATION = (
        <Route
            path="/documentation"
            element={
                <PrivateRoute roles={['admin', 'user']}>
                    <Layout>
                        <Suspense fallback={<Fallback />}>
                            <Documentation />
                        </Suspense>
                    </Layout>
                </PrivateRoute>
            }
        />
    );

    return (
        <>
            {!isAuthenticated &&
                !user.status &&
                currentRoute !== '/verify-code' &&
                currentRoute !== '/authenticate' &&
                currentRoute !== '/forgot' && <Navigate from="/logout" exact to="/login" />}
            {!isAuthenticated &&
                !user.status &&
                currentRoute !== '/verify-code' &&
                currentRoute !== '/authenticate' &&
                currentRoute !== '/forgot' && <Navigate from="/" to="/login" />}
            {isAuthenticated && config && config.googleMapsApiKey && (
                <LoadScript
                    googleMapsApiKey={config.googleMapsApiKey}
                    onLoad={() => setGoogleMapsApiLoaded(true)}
                    onError={(err) => {
                        console.error('Error loading Google Maps script:', err);
                        setLoadError(err);
                    }}
                ></LoadScript>
            )}
            <Routes>
                {UNPRIVATE_LOGIN}
                {UNPRIVATE_FORGOT}
                {UNPRIVATE_AUTHENTICATE}
                {PRIVATE_HOME}
                {PRIVATE_DASHBOARD}
                {PRIVATE_CONNECTIONS}
                {PRIVATE_CONNECTION_DETAILS}
                {PRIVATE_DOCUMENTATION}
            </Routes>
        </>
    );
};

export default App;
