import React, { useState, useContext } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import AuthService from '../../../Services/AuthService';
import { AuthContext } from '../../../Context/AuthContext';
import { Link } from 'react-router-dom';

const ProfileMenu = ({ toggleTopDrawer, openSupportModal }) => {
    const [menu, setMenu] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const { user, setIsAuthenticated, setUser } = useContext(AuthContext);

    const handleItemHover = (index) => {
        setSelectedIndex(index);
    };

    const handleItemLeave = () => {
        setSelectedIndex(null);
    };

    const onClickLogoutHandler = () => {
        AuthService.logout().then((data) => {
            if (data.success) {
                setUser(data.user);
                setIsAuthenticated(false);
                window.location.href = '/login';
            }

            return false;
        });
    };

    return (
        <>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                    style={{ background: '#ffffff' }}
                    aria-label={user.email}
                >
                    {window.innerWidth > 1200 && (
                        <span
                            className="d-none d-xl-inline-block ms-2 me-1"
                            style={{ color: '#5B646C' }}
                        >
                            {user.email}
                        </span>
                    )}
                    <i
                        className="mdi mdi-chevron-down d-none d-xl-inline-block"
                        style={{
                            transition: 'transform 200ms linear',
                            transform: `rotateZ(${!menu ? 0 : '180deg'})`,
                            display: 'inline-block',
                            verticalAlign: 'baseline',
                            color: '#5B646C',
                        }}
                    ></i>
                </DropdownToggle>
                <DropdownMenu align="end" style={{ background: '#FFFFFF' }}>
                    <Link
                        to="/logout"
                        className="dropdown-item"
                        onClick={onClickLogoutHandler}
                        onMouseEnter={() => handleItemHover(1)}
                        onMouseLeave={handleItemLeave}
                        style={{
                            background: selectedIndex === 1 ? '#e1edf5' : '#FFFFFF',
                            fontWeight: selectedIndex === 1 ? 'bold' : 'normal',
                        }}
                    >
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                        <span
                            className="ms-1"
                            style={{ color: selectedIndex === 1 ? '#5B646C' : '#5B646C' }}
                        >
                            Logout
                        </span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default ProfileMenu;
