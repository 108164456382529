import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

const PrivateRoute = ({ children, roles }) => {
    const { isAuthenticated, is2fa, user } = useContext(AuthContext);

    if (!isAuthenticated && !is2fa) return <Navigate to="/login" replace />;

    if (!roles.includes(user.role)) return <Navigate to="/dashboard" replace />;

    //if(isAuthenticated && is2fa)
    return children; // eslint-disable-line react-hooks/exhaustive-deps
};

export default PrivateRoute;
