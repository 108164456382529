import forge from 'node-forge';

const RSA_PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqUPdnKp7MojTTt0iW4QG
CzRJZnlm8clr771fuj5mKXG4tri8vCDpUIqA7O5UQKaHIfTwmozx/gs0BlHCNQim
Xc1nvx8dtRyIMx/dzNZPP6Y7L3uwTMNh9Jz9it+fh06V/6N7JlB6l1tw7UzwgRkT
YhHapOPPKiB4GZ1dXgzFoYcNo8471th0EJv7Bk4s8mkkQ6BHjxJy+B4th+KgPTVn
TCMQVfhO1XF7xjGye9qeWtDWt4HRxnI8JtLzrxTNRrpaBJI2xW02pZ7CZpVb1DuU
b3UouKKwdC4X+5PtoL3VE+fXbUmyBVP9Bi6wEkFKAu0qlmbR/ZffrmDJEbEDwqx5
4QIDAQAB
-----END PUBLIC KEY-----`;

export const encryptPassword = (password) => {
    const publicKey = forge.pki.publicKeyFromPem(RSA_PUBLIC_KEY);
    const encrypted = publicKey.encrypt(password, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
    });
    return forge.util.encode64(encrypted);
};