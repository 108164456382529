import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import AuthService from '../../Services/AuthService';
import Message from '../../components/Message/Message';
import { AuthContext } from '../../Context/AuthContext';
import { Row, Col, CardBody, Card, Container, Form, Input, Label, FormFeedback } from 'reactstrap';

// import images
import profile from '../../assets/images/crypto/features-img/img-1.webp';
import logo from '../../assets/images/logos/dark_large.webp';

const Login = () => {
    const [user, setUser] = useState({ username: '', password: '' });
    const [message, setMessage] = useState(null);
    const [submit, setSubmit] = useState(false);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const emailCheck =
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
            user.username.trim(),
        );

    const onChange = (e) => {
        setSubmit(false);
        setMessage(null);
        const { name, value } = e.target;
        setUser((prevUser) => ({ ...prevUser, [name]: value }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
        AuthService.login(user).then((data) => {
            const { user, message, userMatch } = data;

            if (userMatch) {
                setSubmit(false);
                authContext.setUser(user);
                Axios.post('api/user/generate-code', {
                    id: user,
                }).then((res) => (res.data === 'Code Sent' ? navigate('/authenticate') : null));
            } else setMessage(message);
        });
    };

    return (
        <>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden shadow-lg">
                                <div className="bg-blocktrace">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-light p-4">
                                                <h5 className="text-light">Welcome!</h5>
                                                <p>Sign in to FUSION.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img
                                                src={profile}
                                                alt="Server with network connections"
                                                className="img-fluid"
                                                width="176"
                                                height="103.18"
                                                rel="preload"
                                                fetchpriority="high"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="auth-logo">
                                        <Link to="#" className="auth-logo-dark">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt="Large dark BlockTrace logo"
                                                        className="rounded-circle"
                                                        width="60"
                                                        height="60"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => onSubmit(e, user)}
                                        >
                                            <div className="mb-3">
                                                <Label htmlFor="username" className="form-label">
                                                    Email
                                                </Label>
                                                <Input
                                                    id="username"
                                                    name="username"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="text"
                                                    onChange={onChange}
                                                    invalid={
                                                        submit &&
                                                        (!user.username ||
                                                            (user.password && message) ||
                                                            emailCheck)
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {!user.username && (
                                                    <FormFeedback>
                                                        Email cannot be blank
                                                    </FormFeedback>
                                                )}
                                                {user.username && emailCheck && (
                                                    <FormFeedback>
                                                        Invalid Email Format
                                                    </FormFeedback>
                                                )}
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password">
                                                    Password
                                                </Label>
                                                <div className="input-group auth-pass-inputgroup">
                                                    <Input
                                                        id="password"
                                                        name="password"
                                                        onChange={onChange}
                                                        placeholder="Enter Password"
                                                        className="form-control"
                                                        type="password"
                                                        invalid={
                                                            submit &&
                                                            ((!user.username && !user.password) ||
                                                                (user.username && !emailCheck)) &&
                                                            (message || !user.password)
                                                                ? true
                                                                : false
                                                        }
                                                    />

                                                    {!user.password && (
                                                        <FormFeedback>
                                                            Password cannot be blank
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="text-danger"
                                                style={{ textAlign: 'center' }}
                                            >
                                                {message ? (
                                                    <Message id="message" message={message} />
                                                ) : null}
                                            </div>
                                            <div className="mt-4 d-grid">
                                                <button
                                                    className="btn btn-blocktrace btn-block "
                                                    type="submit"
                                                >
                                                    Log In
                                                </button>
                                            </div>
                                            <div className="mt-4 text-center">
                                                <Link to="/forgot" className="text-muted">
                                                    <i className="mdi mdi-lock me-1" /> Forgot your
                                                    password?
                                                </Link>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>Copyright © {new Date().getFullYear()} BLOCKTRACE, LLC.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Login;
