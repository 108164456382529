import React, { createContext, useState } from 'react';

export const ActiveContext = createContext();
const ActiveSidebar = ({ children }) => {
    const [collapse, setCollapse] = useState(false);
    const [collapse1, setCollapse1] = useState(false);
    const [active, setActive] = useState({
        home: true,
        admin: false,
        dashboard: false,
        connections_tab: false,
        connections: false,
        connections_detail: false,
        documentation: false
    })

    return (
        <ActiveContext.Provider value={{ active, setActive, collapse, setCollapse, collapse1, setCollapse1}}>
            {children}
        </ActiveContext.Provider>
    )
}

export default ActiveSidebar